section > header{
    background-color: purple !important;
    color: white;
}

header > button
{
    color: white !important;
}

.container{
    background-color: #eee;
}