.center_image {
    display: flex;
    justify-content: center;
    align-items: center;
    height:  100%
}

main{
    background-color: #eee;
}

thead{
    background-color: 'purple' !important; 
    color: "white"  !important;
}